<template>
  <v-card>
    <v-card-text>
      <v-card-text>
        <v-text-field
          v-model="datosFiscales.rfc"
          label="RFC"
          type="text"
          :rules="[rules.required]"
        />
        <v-text-field
          v-model="datosFiscales.razon_social"
          label="Razon social"
          type="text"
          :rules="[rules.required]"
        />
        <v-select
          v-model="datosFiscales.uso_factura"
          label="Uso Factura"
          :rules="[rules.required]"
          :items="usosFactura"
          :item-text="item => `${item.codigo} - ${item.dato}`"
          item-value="id"
          dense
          solo
        />
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from '@/mixins/formsValidations.js'

export default {
  mixins: [validationMixin],
  props: {
    datosFiscales: {
      type: Object,
    },
    usosFactura: {
      type: Array,
      default: () => [],
    }
  }
}
</script>

<style>

</style>